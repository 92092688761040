// import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';

import Image4 from '@assets/pictures/Dolby_01-copy.png';
import tc from '@assets/pictures/svgs/tc.png';
import clockImg from '@assets/pictures/svgs/clock_poster.svg';
import calenderImg from '@assets/pictures/svgs/calendar-poster.svg';
// import YouTubeImage from '@assets/pictures/youtube_image.png';
import React, { useState, useRef, useEffect } from 'react';
// import MovieCardNew from './partials/MovieCardNew';
import { useParams } from 'react-router';
// import useIsMobile from '../../customHooks/useIsMobile';
import moment from 'moment';
import { getLangSpecificAttribute } from '@helper/languages';
import { useSelector, useDispatch } from 'react-redux';
import {
  useGetNowShowingQuery,
  useGetUpcomingQuery,
} from '@store/rtk/rtk.movieLists';
// Components
import MovieService from '@apiService/MovieService';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import TrailerIcon from '@assets/pictures/svgs/play_new_1.svg';
import ModalVideo from 'react-modal-video';
import dummyPosterStatic from '@assets/pictures/default-img.png';
import useIsMobile from '@src/customHooks/useIsMobile';

const ExperienceTab1 = ({ t, isUpcoming = false }) => {
  const dummyPoster = window.localStorage.getItem('placeholder_image')
    ? window.localStorage.getItem('placeholder_image')
    : dummyPosterStatic;
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { movie_experience_id, movie_experience } = useParams();
  console.log(movie_experience, 'movie_experience');
  const history = useHistory();
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { lang_id } = selected_language;
  const isAdvance = useSelector((state) => state.movies.isAdvance);
  const [isOpen, setOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [videoChannel, setVideoChannel] = useState(null);
  const { data: todaysScreeningsMovies, isLoading: nowShowingLoading } =
    useGetNowShowingQuery({ limit: 1000, currentPage: 1 });
  // console.log(todaysScreeningsMovies?.data, 'todaysScreeningsMovies?.data');

  const { data: comingSoonMovies, isLoading: comingSoonLoading } =
    useGetUpcomingQuery();

  //  const dilbyFlteredData = (id) => {
  //   return id > 0;
  //   };

  const [SingleExpPagesData, setSingleExpPagesData] = useState([]);
  const SingleExpPagesDataFunc = () => async (dispatch) => {
    try {
      let payload = {
        movie_experience_id: movie_experience_id ? movie_experience_id : 0,
      };
      const response = await MovieService.GetSingleMovieExperience(payload);
      const { data } = response;
      if (data?.status) {
        setSingleExpPagesData(data.Records);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    dispatch(SingleExpPagesDataFunc());
  }, []);
  // console.log(SingleExpPagesData, 'SingleExpPagesData');

  let expTranslatedData = [];
  SingleExpPagesData?.map((x) => {
    return expTranslatedData.push(JSON.parse(x?.translation_data));
  });
  // expTranslatedData[0]?.map((t)=>{
  //   return t?.movie_experience_description
  // })
  // console.log(expTranslatedData, 'expTranslatedData');
  const epixExperienceName = [
    {
      exp_name: 'STANDARD',
      exp_show_screen_ids: [22, 21, 17, 25, 24],
    },
    {
      exp_name: 'KINDER',
      exp_show_screen_ids: [12],
    },
    {
      exp_name: 'XL',
      exp_show_screen_ids: [23, 11, 27],
    },
    {
      exp_name: 'VIP',
      exp_show_screen_ids: [26],
    },
  ];

  let screenIdtobeFilterArr = epixExperienceName?.filter((t) => {
    return t?.exp_name == movie_experience;
  });

  let screenIdToBeFiltered = screenIdtobeFilterArr
    ?.map((elem) => elem?.exp_show_screen_ids)
    .flatMap((r) => r);

  let advanceData = todaysScreeningsMovies?.data?.filter((movie) => {
    let result = !(
      !(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) ||
      (!!(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) &&
        moment(
          movie?.original_release_date ||
            movie?.original_mrrdr_release_date ||
            movie?.mrrdr_release_date,
        ).isSameOrBefore(moment()))
    );

    return result;
  });
  // console.log(advanceData, 'advance');

  let advanceDolbyArr = [];
  // console.log(advanceData, 'advanceData');
  advanceDolbyArr = advanceData?.filter((x) => {
    return screenIdToBeFiltered?.includes(x?.screen_id);
  });

  // console.log(advanceDolbyArr, 'advanceDlbyArr');
  // console.log(uniqueComingSoonMovies, 'uniqueComingSoonMovies');

  let nowshowingdata = todaysScreeningsMovies?.data?.filter((movie) => {
    let result =
      !(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) ||
      (!!(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) &&
        moment(
          movie?.original_release_date ||
            movie?.original_mrrdr_release_date ||
            movie?.mrrdr_release_date,
        ).isSameOrBefore(moment()));

    return result;
  });
  // console.log(nowshowingdata, 'nowshowingdata');
  let nowshowing_dolbyresultArr = [];
  // console.log(nowshowingdata, 'nowshowingdata');

  // console.log(screenIdToBeFiltered, 'screenIdToBeFiltered');
  nowshowing_dolbyresultArr = nowshowingdata?.filter((x) => {
    return screenIdToBeFiltered?.includes(x?.screen_id);

    // nowshowing_dolbyresultArr = [...nowshowing_dolbyresultArr, x];
  });
  // console.log(nowshowingdata, 'uniqueComingSoonMovies');
  // console.log(nowshowing_dolbyresultArr, 'nowshowing_dolbyresultArr');
  // console.log(nowshowing_dolbyresultArr, 'nowshowing_dolbyresultArr');

  let NowShowingAndAdvanceArrayCombine = [];

  NowShowingAndAdvanceArrayCombine =
    nowshowing_dolbyresultArr || advanceDolbyArr
      ? advanceDolbyArr.concat(nowshowing_dolbyresultArr)
      : [];
  const uniqueComingSoonMovies = {
    ...comingSoonMovies,
    data: [
      ...new Map(
        comingSoonMovies?.data?.map((item) => [item['movie_id'], item]),
      ).values(),
    ],
  };
  let upcomingDolbyArray = [];

  // uniqueComingSoonMovies?.data?.map((x) => {
  //   if (x.screen_type_id == 3 || x?.format_name.includes('KINDER')) {
  //     upcomingDolbyArray = [...upcomingDolbyArray, x];
  //   }
  // });

  return (
    <>
      <div className="align_new">
        <ModalVideo
          autoplay
          channel={videoChannel}
          isOpen={isOpen}
          videoId={currentVideoId}
          onClose={() => setOpen(false)}
        />
        <div className="left_right_align">
          <CenteredTitle
            //   firstHalf={`${t('Privacy Policy')}`}
            firstHalf={SingleExpPagesData[0]?.movie_experience}
            secondHalf={''}
          />
        </div>
        {/* bg_dolby */}
        <div className="">
          <img
            src={
              SingleExpPagesData[0] &&
              SingleExpPagesData[0]?.movie_experience_image_banner_url
                ? SingleExpPagesData[0]?.movie_experience_image_banner_url
                : tc
            }
            className="img-fluid experience_banner"
          ></img>
        </div>

        <div className="container-fluid" style={{ marginTop: '30px' }}>
          <section className="row">
            <article className="col-xl-12 mt-3">
              <h2 className="font-weight-bold sub_title_exp">
                About The Experience
              </h2>
              {expTranslatedData[0]?.map((item, index) => (
                <div
                  style={{
                    padding: isMobile ? '5px' : '20px 0px',
                    textAlign: 'left',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: item?.movie_experience_description
                      ? item?.movie_experience_description.replaceAll('&lt;', '<')
                      : '',
                  }}
                  className="offer_description_wrapper"
                />
                // <p
                //   }}
                // >
                //   {item?.movie_experience_description}
                // </p>
              ))}
            </article>
          </section>

          <section className="">
            <div className="row">
              <article className="col-xl-12 mt-3">
                <h2 className="font-weight-bold sub_title_exp">
                  Movies Showing in {SingleExpPagesData[0]?.movie_experience}
                </h2>
              </article>
            </div>
            <section
              className="movie_n_fests_new"
              style={{ padding: !isMobile ? '20px' : '' }}
            >
              {NowShowingAndAdvanceArrayCombine?.length > 0 ? (
                <div className="movie_n_fests_grid">
                  {/* Now Showing Div Starts Here */}
                  {nowshowing_dolbyresultArr?.map((props) => {
                    return (
                      <div className="d-block parent_poster_wrapper">
                        <div
                          className={`${'movie_card_new'} `}
                          // onMouseEnter={() => setIsShown(true)}
                          // onMouseLeave={() => setIsShown(false)}
                          // style={{ width: isUpcoming ? '150%' : '' }}
                          onClick={() =>
                            history.push(
                              `/now-showing-booking/${props?.movie_id}/${props?.content_lang_id}/`,
                            )
                          }
                        >
                          {/*has-tooltip previous class for on hover button*/}
                          {/* Image */}

                          <figure className="">
                            <img
                              src={
                                getLangSpecificAttribute(
                                  props?.languageSpecificImages ||
                                    props?.altMovieContent,
                                  lang_id,
                                  'artwork',
                                ) || dummyPosterStatic
                              }
                              className="img-fluid poster_exp_img"
                              alt={t('alt.Movie Poster')}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = dummyPosterStatic;
                              }}
                            />

                            <div className="details">
                              {props?.trailer_array?.map(
                                (trailer, trailerIndex) => (
                                  <img
                                    key={trailerIndex[0]}
                                    src={TrailerIcon}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      if (trailer?.mt_link?.includes('youtube')) {
                                        setVideoChannel('youtube');
                                        setCurrentVideoId(
                                          trailer?.mt_link?.split('=')[1],
                                        );
                                      }
                                      if (trailer?.mt_link?.includes('vimeo')) {
                                        setVideoChannel('vimeo');
                                        setCurrentVideoId(
                                          trailer?.mt_link
                                            ?.split('/')
                                            .pop()
                                            .split('.')?.[0],
                                        );
                                      }
                                      setOpen(true);
                                    }}
                                  />
                                ),
                              )}
                              <button className="book_now_btn">
                                {!isUpcoming ? 'Book Now' : 'More'}
                              </button>
                            </div>
                          </figure>

                          {/*Tool tip on hover */}
                          {/*{
          !!isUpcoming && (
          <p className="tooltip-new">

       <button className="btn_hov">
         {props?.mrrdr_release_date && t('From')} <br/>
            {props?.mrrdr_release_date && moment(props?.mrrdr_release_date).format('DD MMM YYYY')}
            </button>
          </p>
          )}*/}

                          <div className="movie_poster_details">
                            <div
                              className={`d-flex justify-content-between ${
                                isMobile ? 'mb-2' : ''
                              }`}
                            >
                              <div className="responsive_font_movie_title">
                                {getLangSpecificAttribute(
                                  props?.altMovieContent,
                                  lang_id,
                                  'mc_title',
                                ) ||
                                  props?.movie_title ||
                                  props?.mc_title}
                                <br />
                              </div>
                              <div className="responsive_font_movie_title">
                                {props?.rating || props?.rating}
                              </div>
                            </div>
                            <div className="">
                              <span
                                className="genre_poster"
                                style={{ wordBreak: isMobile ? 'break-all' : '' }}
                              >
                                {props?.genre_name}
                              </span>
                            </div>

                            <div className="d-flex align-items-end justify-content-between">
                              <>
                                <div className="time_poster">
                                  <img
                                    src={calenderImg}
                                    className="clock_img mr-1"
                                  />
                                  {moment(props?.mrrdr_release_date).format(
                                    'MMM Do',
                                  )}
                                </div>

                                <div className="time_poster">
                                  <img src={clockImg} className="clock_img" />{' '}
                                  {`${Math.floor(props?.mrrdr_runtime / 60)} hrs ${
                                    props?.mrrdr_runtime % 60 != 0
                                      ? (props?.mrrdr_runtime % 60) + ' mins'
                                      : ''
                                  }`}
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* Now Showing Div Ends Here */}

                  {/* {Advance Div Starts Here} */}
                  {advanceDolbyArr.map((propsAdvance) => {
                    return (
                      <div className="d-block parent_poster_wrapper">
                        {/* <div className="advance_tag">
                          <span>Advance</span>
                        </div> */}

                        <div
                          className={`${'movie_card_new'} `}
                          // onMouseEnter={() => setIsShown(true)}
                          // onMouseLeave={() => setIsShown(false)}
                          // style={{ width: isUpcoming ? '150%' : '' }}
                          onClick={() =>
                            history.push(
                              `/now-showing-booking/${propsAdvance?.movie_id}/${propsAdvance?.content_lang_id}/`,
                            )
                          }
                        >
                          {/*has-tooltip previous class for on hover button*/}
                          {/* Image */}

                          <figure className="">
                            <img
                              src={
                                getLangSpecificAttribute(
                                  propsAdvance?.languageSpecificImages ||
                                    propsAdvance?.altMovieContent,
                                  lang_id,
                                  'artwork',
                                ) || dummyPosterStatic
                              }
                              className="img-fluid poster_exp_img"
                              alt={t('alt.Movie Poster')}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = dummyPosterStatic;
                              }}
                            />

                            <div className="details">
                              {propsAdvance?.trailer_array?.map(
                                (trailer, trailerIndex) => (
                                  <img
                                    key={trailerIndex[0]}
                                    src={TrailerIcon}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      if (trailer?.mt_link?.includes('youtube')) {
                                        setVideoChannel('youtube');
                                        setCurrentVideoId(
                                          trailer?.mt_link?.split('=')[1],
                                        );
                                      }
                                      if (trailer?.mt_link?.includes('vimeo')) {
                                        setVideoChannel('vimeo');
                                        setCurrentVideoId(
                                          trailer?.mt_link
                                            ?.split('/')
                                            .pop()
                                            .split('.')?.[0],
                                        );
                                      }
                                      setOpen(true);
                                    }}
                                  />
                                ),
                              )}
                              <button className="book_now_btn">{'Book Now'}</button>
                            </div>
                          </figure>

                          <div className="movie_poster_details">
                            <div
                              className={`d-flex justify-content-between ${
                                isMobile ? 'mb-2' : ''
                              }`}
                            >
                              <div className="responsive_font_movie_title">
                                {getLangSpecificAttribute(
                                  propsAdvance?.altMovieContent,
                                  lang_id,
                                  'mc_title',
                                ) ||
                                  propsAdvance?.movie_title ||
                                  propsAdvance?.mc_title}
                                <br />
                              </div>
                              <div className="responsive_font_movie_title">
                                {propsAdvance?.rating || propsAdvance?.rating}
                              </div>
                            </div>
                            <div className="">
                              <span
                                className="genre_poster"
                                style={{ wordBreak: isMobile ? 'break-all' : '' }}
                              >
                                {propsAdvance?.genre_name}
                              </span>
                            </div>

                            <div className="d-flex align-items-end justify-content-between">
                              <>
                                <div className="time_poster">
                                  <img
                                    src={calenderImg}
                                    className="clock_img mr-1"
                                  />

                                  {moment(propsAdvance?.mrrdr_release_date).format(
                                    'MMM Do',
                                  )}
                                </div>

                                <div className="time_poster">
                                  <img src={clockImg} className="clock_img" />{' '}
                                  {`${Math.floor(
                                    propsAdvance?.mrrdr_runtime / 60,
                                  )} hrs ${
                                    propsAdvance?.mrrdr_runtime % 60 != 0
                                      ? (propsAdvance?.mrrdr_runtime % 60) + ' mins'
                                      : ''
                                  }`}
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* {Advance Div Ends Here} */}
                </div>
              ) : (
                <div>No Movies Found</div>
              )}
            </section>

            {/* <div class="flex-container">
              <div className="dolby-movie">
                <div>
                  <img
                    src={Image1}
                    alt="img"
                    height={300}
                    width={250}
                    style={{ borderRadius: '25px' }}
                  ></img>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src={Image2}
                    alt="img"
                    height={300}
                    width={250}
                    style={{ borderRadius: '25px' }}
                  ></img>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src={Image3}
                    alt="img"
                    height={300}
                    width={250}
                    style={{ borderRadius: '25px' }}
                  ></img>
                </div>
              </div>
            </div> */}
          </section>
        </div>
      </div>
    </>
  );
};

export default withNamespaces()(ExperienceTab1);
