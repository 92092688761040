// import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';

import Image from '@assets/pictures/F&B-Banner.jpg';

import clockImg from '@assets/pictures/svgs/clock_poster.svg';
import calenderImg from '@assets/pictures/svgs/calendar-poster.svg';
// import YouTubeImage from '@assets/pictures/youtube_image.png';
import React, { useState, useRef } from 'react';
// import MovieCardNew from './partials/MovieCardNew';

// import useIsMobile from '../../customHooks/useIsMobile';
import moment from 'moment';
import { getLangSpecificAttribute } from '@helper/languages';
import { useSelector, useDispatch } from 'react-redux';
import {
  useGetNowShowingQuery,
  useGetUpcomingQuery,
} from '@store/rtk/rtk.movieLists';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import TrailerIcon from '@assets/pictures/svgs/play_new_1.svg';
import ModalVideo from 'react-modal-video';
import dummyPosterStatic from '@assets/pictures/default-img.png';
import useIsMobile from '@src/customHooks/useIsMobile';
import { Row, Col } from 'react-bootstrap';
import Image1 from '@assets/pictures/1.jpg';
import Image2 from '@assets/pictures/2.jpg';
import Image3 from '@assets/pictures/3.jpg';
import Image4 from '@assets/pictures/4.jpg';
import Image5 from '@assets/pictures/5.jpg';
import Image6 from '@assets/pictures/6.jpg';
import Image7 from '@assets/pictures/7.jpg';
import Image8 from '@assets/pictures/8.jpg';
import Image9 from '@assets/pictures/9.jpg';
import Image10 from '@assets/pictures/10.jpg';
import Image11 from '@assets/pictures/11.jpg';
import Image12 from '@assets/pictures/12.jpg';

const FoodBeverages = ({ t, isUpcoming }) => {
  const dummyPoster = window.localStorage.getItem('placeholder_image')
    ? window.localStorage.getItem('placeholder_image')
    : dummyPosterStatic;
  const isMobile = useIsMobile();
  const history = useHistory();
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { lang_id } = selected_language;
  const isAdvance = useSelector((state) => state.movies.isAdvance);
  const [isOpen, setOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [videoChannel, setVideoChannel] = useState(null);
  const { data: todaysScreeningsMovies, isLoading: nowShowingLoading } =
    useGetNowShowingQuery({ limit: 1000, currentPage: 1 });
  // console.log(todaysScreeningsMovies?.data, 'todaysScreeningsMovies?.data');

  const { data: comingSoonMovies, isLoading: comingSoonLoading } =
    useGetUpcomingQuery();

  //  const dilbyFlteredData = (id) => {
  //   return id > 0;
  //   };

  let advanceData = todaysScreeningsMovies?.data?.filter((movie) => {
    let result = !(
      !(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) ||
      (!!(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) &&
        moment(
          movie?.original_release_date ||
            movie?.original_mrrdr_release_date ||
            movie?.mrrdr_release_date,
        ).isSameOrBefore(moment()))
    );

    return result;
  });
  // console.log(advanceData, 'advance');

  let advanceDolbyArr = [];

  advanceData?.map((x) => {
    if (x.screen_type_id == 3) {
      advanceDolbyArr = [...advanceDolbyArr, x];
    }
  });

  // console.log(advanceDolbyArr, 'advanceDlbyArr');
  // console.log(uniqueComingSoonMovies, 'uniqueComingSoonMovies');

  let nowshowingdata = todaysScreeningsMovies?.data?.filter((movie) => {
    let result =
      !(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) ||
      (!!(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) &&
        moment(
          movie?.original_release_date ||
            movie?.original_mrrdr_release_date ||
            movie?.mrrdr_release_date,
        ).isSameOrBefore(moment()));

    return result;
  });
  // console.log(nowshowingdata, 'nowshowingdata');
  let nowshowing_dolbyresultArr = [];

  nowshowingdata?.map((x) => {
    if (x.screen_type_id == 3) {
      nowshowing_dolbyresultArr = [...nowshowing_dolbyresultArr, x];
    }
  });

  // console.log(nowshowing_dolbyresultArr, 'nowshowing_dolbyresultArr');
  const uniqueComingSoonMovies = {
    ...comingSoonMovies,
    data: [
      ...new Map(
        comingSoonMovies?.data?.map((item) => [item['movie_id'], item]),
      ).values(),
    ],
  };
  let upcomingDolbyArray = [];

  uniqueComingSoonMovies?.data?.map((x) => {
    if (x.screen_type_id == 3) {
      upcomingDolbyArray = [...upcomingDolbyArray, x];
    }
  });
  // console.log(nowshowing_dolbyresultArr, 'nowshowing_dolbyresultArr');

  return (
    <>
      <div className="align_new">
        <ModalVideo
          autoplay
          channel={videoChannel}
          isOpen={isOpen}
          videoId={currentVideoId}
          onClose={() => setOpen(false)}
        />
        {/* <div className="left_right_align">
          <CenteredTitle
          
         firstHalf={`${'Food And Beverages'}`}
            secondHalf={''}
          />
        </div> */}
        {/* bg_dolby */}
        {isMobile ? (
          ''
        ) : (
          <div>
            <img src={Image} className="img-fluid experience_banner"></img>
          </div>
        )}

        <div className="container-fluid">
          <section className="row">
            <article className="col-xl-12 mt-3">
              <h2 className="font-weight-bold sub_title_exp">
                Star Cinemas : Good Food Good Mood !!!.
              </h2>
              <p style={{ padding: isMobile ? '0px' : '0px', textAlign: 'left' }}>
                Star Cinemas is committed to serve you all your favourite snacks to
                perfectly complement your movie. From Popcorn, nachos,premium
                confectionary, hot appetizers, soft drinks, we have it all.
              </p>
            </article>
          </section>

          <section className="">
            <div className="row">
              <article className="col-xl-12 mt-3"></article>
            </div>
            <section
              className="movie_n_fests_new"
              style={{ padding: !isMobile ? '20px' : '' }}
            >
              <Row>
                <Col xl={2} sm={6} md={2}>
                  <figure className="">
                    <img
                      src={Image1}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                  </figure>
                </Col>
                <Col xl={2} sm={6} md={2}>
                  <figure className="">
                    <img
                      src={Image2}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                  </figure>
                </Col>
                <Col xl={2} sm={6} md={2}>
                  <figure className="">
                    <img
                      src={Image3}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                  </figure>
                </Col>
                <Col xl={2} sm={6} md={2}>
                  <figure className="">
                    <img
                      src={Image4}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                  </figure>
                </Col>
                <Col xl={2} sm={6} md={2}>
                  <figure className="">
                    <img
                      src={Image5}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                  </figure>
                </Col>
                <Col xl={2} sm={6} md={2}>
                  <figure className="">
                    <img
                      src={Image6}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                  </figure>
                </Col>
              </Row>
              <Row>
                <Col xl={2} sm={6} md={2}>
                  <figure className="">
                    <img
                      src={Image7}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                  </figure>
                </Col>
                <Col xl={2} sm={6} md={2}>
                  <figure className="">
                    <img
                      src={Image8}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                  </figure>
                </Col>
                <Col xl={2} sm={6} md={2}>
                  <figure className="">
                    <img
                      src={Image9}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                  </figure>
                </Col>
                <Col xl={2} sm={6} md={2}>
                  <figure className="">
                    <img
                      src={Image10}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                  </figure>
                </Col>
                <Col xl={2} sm={6} md={2}>
                  <figure className="">
                    <img
                      src={Image11}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                  </figure>
                </Col>
                <Col xl={2} sm={6} md={2}>
                  <figure className="">
                    <img
                      src={Image12}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                  </figure>
                </Col>
              </Row>
              {/* <div className='row'>
                              <div className='col-12'>
                 <span className='col-2'>
                 <figure className="experience_img">
                    <img
                      src={Image1}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                 </figure>
                </span>
                                  <span className='col-2'>
                                  <figure className="experience_img">
                    <img
                      src={Image2}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                  </figure>

                                  </span>
                                  <span className='col-2'>
                 <figure className="experience_img">
                    <img
                      src={Image3}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                 </figure>
                                  </span>  
                                  <span className='col-2'>
                 <figure className="experience_img">
                    <img
                      src={Image4}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                 </figure>
                                  </span> 
                                  <span className='col-2'>
                 <figure className="experience_img">
                    <img
                      src={Image5}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                 </figure>
                                  </span>
                                  <span className='col-2'>
                 <figure className="experience_img">
                    <img
                      src={Image6}
                      className="img-fluid"
                      alt={t('alt.Movie Poster')}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = dummyPosterStatic;
                      }}
                    />
                 </figure>
                </span>
                                  
                              </div>
                    </div> */}
            </section>

            {/* <div class="flex-container">
              <div className="dolby-movie">
                <div>
                  <img
                    src={Image1}
                    alt="img"
                    height={300}
                    width={250}
                    style={{ borderRadius: '25px' }}
                  ></img>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src={Image2}
                    alt="img"
                    height={300}
                    width={250}
                    style={{ borderRadius: '25px' }}
                  ></img>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src={Image3}
                    alt="img"
                    height={300}
                    width={250}
                    style={{ borderRadius: '25px' }}
                  ></img>
                </div>
              </div>
            </div> */}
          </section>
        </div>
      </div>
    </>
  );
};

export default withNamespaces()(FoodBeverages);
