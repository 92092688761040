import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setStage, setisAdvance } from '../../store/movies/movieActions';
import { motion } from 'framer-motion';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../../customHooks/useIsMobile';
// import dummyPosterStatic from '@assets/pictures/dummyPoster.png';
import dummyPosterStatic from '@assets/pictures/default-img.png';
import { BASEURL } from '../../config';
import { checkIfURLSame, getLabelToshow } from '@helper/formatting';
import { getLangSpecificAttribute } from '@helper/languages';
import moment from 'moment';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import TrailerIcon from '@assets/pictures/svgs/play_new_1.svg';
// import { fetchAllMoviesSchedules } from '../store/movies/movieActions';
import ModalVideo from 'react-modal-video';
import useIsTablet from '@src/customHooks/useIsTablet.js';
import useIsDesktop from '@src/customHooks/useIsDesktop.js';
import TicketCut from '@assets/pictures/mobiledividercard.png';

// Images
import poster from '@assets/pictures/sample-poster-new.png';
import clockImg from '@assets/pictures/svgs/clock_poster.svg';
import calenderImg from '@assets/pictures/svgs/calendar-poster.svg';
import moviecarddivider from '@assets/pictures/moviecarddivider.png';
const _ = require('lodash');

const MovieCardNew = (props) => {
  const {
    slider,
    onClick,
    classes,
    isUpcoming = false,
    // isAdvance,
    now_demand_count = 0,
  } = props;
  const [isShown, setIsShown] = useState(false);
  const { pathname } = useLocation();
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { lang_id } = selected_language;
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const organization = useSelector((state) => state.movies.organizationDetails);
  const {
    org_logo_url,
    org_mycinema_logo,
    org_name,
    dummy_poster: dummyPosterDynamic,
  } = organization;
  const [isOpen, setOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [videoChannel, setVideoChannel] = useState(null);
  const dummyPoster = window.localStorage.getItem('placeholder_image')
    ? window.localStorage.getItem('placeholder_image')
    : dummyPosterStatic;

  const abc = () => {};
  const { iso_2 } = selected_language;
  const history = useHistory();
  const t = props.t;
  const isTablet = useIsTablet();

  let available_movie_genre = props?.genres_with_translations
    .map((y) => {
      return y?.g_name;
    })
    .join(' , ');
  let available_movie_lang = props?.languages_with_translations
    ?.filter((t) => t.md_is_active == 'Y')
    .map((x) => {
      return x?.lang_name;
    });

  // console.log(available_movie_lang, 'available_movie_lang');
  let filtered_movie_lang = [...new Set(available_movie_lang)].join(' , ');

  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isDeskTop = useIsDesktop();
  const isAdvance = useSelector((state) => state.movies.isAdvance);

  const labelsToShow = getLabelToshow(props?.label_to_show);
  let allMoviesSchedules = useSelector((state) => state.movies.allMoviesSchedules);
  let allMoviesSchedulesLoading = useSelector(
    (state) => state.movies.allMoviesSchedulesLoading,
  );
  let allMoviesSchedulesError = useSelector(
    (state) => state.movies.allMoviesSchedulesError,
  );
  let allMoviesSchedulesLanguages = useSelector(
    (state) => state.movies.allMoviesSchedulesLanguages,
  );
  let movieSchedules = allMoviesSchedules.movieSchedules;
  // let movie_lang_names = props?.lang_names.toString().split(' ').join(',');

  // console.log(movie_lang_names, 'movie_lang_names');

  // const calculateTopPosition = () => {
  //   if (isImageLoaded) {
  //     if (isTablet) {
  //       return "calc(83% - 30px)";
  //     } else if (isMobile) {
  //       return "calc(81% - 30px)";
  //     } else {
  //       return "calc(88% - 30px)";
  //     }
  //   } else {
  //     return "calc(84% - 30px)";
  //   }
  // };
  const calculateTopPosition = () =>
    isImageLoaded
      ? isTablet
        ? 'calc(83% - 30px)'
        : isMobile
        ? 'calc(79% - 30px)'
        : 'calc(88% - 30px)'
      : 'calc(84% - 30px)';

  const imageHeight = () => {
    if (isDeskTop) {
      return '364px';
    } else if (isTablet) {
      return '245px';
    } else {
      return '196px';
    }
  };

  // const circleSize = isTablet ? 30 : (isMobile ? 20 : 40);
  const circleSize = !isMobile ? 30 : 20;
  const leftRight = isMobile ? -30 : -40;

  return (
    <>
      {/* <div class="container_custom">
        <img
          src={
            getLangSpecificAttribute(
              props?.languageSpecificImages || props?.altMovieContent,
              lang_id,
              'artwork',
            ) || dummyPoster
          }
          alt="Avatar"
          class="image_custom"
          style={{ width: '100%' }}
        />
       
      </div> */}
      <ModalVideo
        autoplay
        channel={videoChannel}
        isOpen={isOpen}
        videoId={currentVideoId}
        onClose={() => setOpen(false)}
      />

      <div className="d-block parent_poster_wrapper">
        {/* {isAdvance && (
          <>
            <div className="advance_tag">
              <span>Advance</span>
            </div>
          </>
        )} */}
        <motion.article
          className={`${slider ? 'slider_spacing' : ''} ${classes}`}
          initial={{ x: -75, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.75 }}
          title={
            getLangSpecificAttribute(props?.altMovieContent, lang_id, 'mc_title') ||
            props?.movie_title ||
            props?.mc_title
          }
        >
          <div
            className={`${'movie_card_new'} `}
            // onMouseEnter={() => setIsShown(true)}
            // onMouseLeave={() => setIsShown(false)}
            // style={{ width: isUpcoming ? '150%' : '' }}
            onClick={onClick}
          >
            {/*has-tooltip previous class for on hover button*/}
            {/* Image */}

            {/* <figure className="relative">
              {false && isUpcoming && props?.mrrdr_release_date && (
                <div className="movie_card_new_label_wrapper">
                  <span className="movie_card_new_label_background" />
                  <span className="movie_card_new_label">
                    {!isMobile && t('Releasing')}{' '}
                    {moment(props?.mrrdr_release_date).format('DD MMM YYYY')}
                  </span>
                </div>
              )}

              <img
                src={
                  getLangSpecificAttribute(
                    props?.languageSpecificImages || props?.altMovieContent,
                    lang_id,
                    'artwork',
                  ) || dummyPoster
                }
                className="img-fluid"
                alt={t('alt.Movie Poster')}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; 
                  currentTarget.src = dummyPosterStatic;
                }}
              />

              <div class="details">
                {props?.trailer_array?.map((trailer, trailerIndex) => (
                  <img
                    key={trailerIndex[0]}
                    src={TrailerIcon}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (trailer?.mt_link?.includes('youtube')) {
                        setVideoChannel('youtube');
                        setCurrentVideoId(trailer?.mt_link?.split('=')[1]);
                      }
                      if (trailer?.mt_link?.includes('vimeo')) {
                        setVideoChannel('vimeo');
                        setCurrentVideoId(
                          trailer?.mt_link?.split('/').pop().split('.')?.[0],
                        );
                      }
                      setOpen(true);
                    }}
                  />
                ))}
                <button className="book_now_btn">
                  {!isUpcoming ? 'Book Now' : 'More'}
                </button>
                <div className="rating-tag  text-white px-2 py-1 rounded border border-primary" style={{ position: "absolute", top: "15px", right: "15px", background: "#259486" }}>
                  {props?.rating || 'PG13'}
                </div>
              </div>
            </figure> */}

            <figure className="relative">
              {false && isUpcoming && props?.mrrdr_release_date && (
                <div className="movie_card_new_label_wrapper">
                  <span className="movie_card_new_label_background" />
                  <span className="movie_card_new_label">
                    {!isMobile && t('Releasing')}{' '}
                    {moment(props?.mrrdr_release_date).format('DD MMM YYYY')}
                  </span>
                </div>
              )}

              <img
                src={
                  getLangSpecificAttribute(
                    props?.languageSpecificImages || props?.altMovieContent,
                    lang_id,
                    'artwork',
                  ) || dummyPosterStatic
                }
                className="img-fluid"
                alt={t('alt.Movie Poster')}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = dummyPosterStatic;
                }}
                onLoad={() => setIsImageLoaded(true)}
                style={{ height: imageHeight() }}
              />

              <div className="details">
                {props?.trailer_array?.map((trailer, trailerIndex) => (
                  <img
                    key={trailerIndex}
                    src={TrailerIcon}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (trailer?.mt_link?.includes('youtube')) {
                        setVideoChannel('youtube');
                        setCurrentVideoId(trailer?.mt_link?.split('=')[1]);
                      }
                      if (trailer?.mt_link?.includes('vimeo')) {
                        setVideoChannel('vimeo');
                        setCurrentVideoId(
                          trailer?.mt_link?.split('/').pop().split('.')?.[0],
                        );
                      }
                      setOpen(true);
                    }}
                  />
                ))}
                <button className="book_now_btn">
                  {!isUpcoming ? 'Book Now' : 'More'}
                </button>
                <div
                  className="rating-tag  text-white  rounded"
                  style={{
                    position: 'absolute',
                    top: '15px',
                    right: '15px',
                    background: '#259486',
                    height: '22px',
                    width: '50px',
                    fontSize: '13px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {props?.rating || 'PG13'}
                </div>
                <div
                  style={{
                    border: '2px dashed gray',
                    position: 'absolute',
                    top: calculateTopPosition(),
                    right: '0',
                    width: '100%',
                    boxSizing: 'border-box',
                    padding: '0 20px',
                    display: isImageLoaded ? 'block' : 'none',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: `${leftRight}px`,
                        transform: 'translateY(-50%)',
                        width: `${circleSize}px`,
                        height: `${circleSize}px`,
                        borderRadius: '50%',
                        backgroundColor: '#25222d',
                      }}
                    ></div>
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        right: `${leftRight}px`,
                        transform: 'translateY(-50%)',
                        width: `${circleSize}px`,
                        height: `${circleSize}px`,
                        borderRadius: '50%',
                        backgroundColor: '#25222d',
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </figure>

            {/*Tool tip on hover */}
            {/*{
          !!isUpcoming && (
          <p className="tooltip-new">

       <button className="btn_hov">
         {props?.mrrdr_release_date && t('From')} <br/>
            {props?.mrrdr_release_date && moment(props?.mrrdr_release_date).format('DD MMM YYYY')}
            </button>
          </p>
          )}*/}

            {/* Label */}
            {!isUpcoming && labelsToShow?.length > 0 && (
              <div className="movie_card_new_label_wrapper">
                <span className="movie_card_new_label_background" />
                <span className="movie_card_new_label">
                  {getLangSpecificAttribute(
                    labelsToShow,
                    lang_id,
                    'label_name',
                    'lang_id',
                  ) ||
                    labelsToShow[0]?.label ||
                    ''}
                </span>
              </div>
            )}

            <div
              className={`movie_poster_details  ${
                isAdvance || isUpcoming ? 'custom_min_height_adv' : ''
              }`}
              // style={{ height: props?.movie_title.length > 20 ? '90px' : ((!isDeskTop) ? "90px":"auto"),  position: 'relative' }}
              style={{
                height:
                  isMobile && props?.movie_title.length > 20
                    ? '90px'
                    : !isDeskTop
                    ? '90px'
                    : '81px',
                position: 'relative',
              }}
            >
              <div
                className={`d-flex justify-content-center pb-2 ${
                  isMobile ? '' : ''
                }`}
                style={{
                  gap: !isMobile ? '5px' : '6px',
                  marginTop: isMobile || isTablet ? '20px' : '15px',
                  fontWeight: 'normal',
                }}
              >
                <div
                  className="responsive_font_movie_title"
                  style={{ textAlign: 'center' }}
                  // style={{ fontSize: isMobile ? '16px' : (props?.movie_title.length > 16 ? '10px' : '16px') }}
                >
                  {getLangSpecificAttribute(
                    props?.altMovieContent,
                    lang_id,
                    'mc_title',
                  ) ||
                    props?.movie_title ||
                    props?.mc_title}
                  <br />
                  {filtered_movie_lang?.length > 0 && (
                    <div className="time_poster text-center text-white">
                      {filtered_movie_lang} | {available_movie_genre}|
                      {Math.floor(props?.mrrdr_runtime / 60)} hrs{' '}
                      {props?.mrrdr_runtime % 60 != 0
                        ? (props?.mrrdr_runtime % 60) + ' mins'
                        : ''}
                    </div>
                  )}
                </div>
                {/* <div
                  className="responsive_font_movie_title"
                  style={{ fontSize: '13px', color: 'var(--epix-blue)' }}
                >
                  {props?.rating || props?.rating}
                </div> */}
              </div>
              {/* <div className="">
                <span
                  className="genre_poster"
                  style={{ wordBreak: isMobile ? 'break-word' : '' }}
                > */}
              {/* {props?.genre_name} */}
              {/* {available_movie_genre}
                </span>
              </div> */}

              <div
                className={`d-flex align-items-end ${isMobile ? 'flex-wrap' : ''}`}
              >
                {isAdvance || isUpcoming ? (
                  <>
                    {/* <div className="time_poster" style={{ marginRight: '5px' }}>
                      <img
                        src={calenderImg}
                        className="clock_img"
                        style={{
                          marginRight: '3px',
                        }}
                      />
                      {moment(props?.mrrdr_release_date).format('MMM Do')}
                    </div>

                    <div className="time_poster">
                      <img
                        src={isAdvance || isUpcoming ? clockImg : '|'}
                        className="clock_img"
                      />{' '}
                      {`${Math.floor(props?.mrrdr_runtime / 60)} hrs ${props?.mrrdr_runtime % 60 != 0
                        ? (props?.mrrdr_runtime % 60) + ' mins'
                        : ''
                        }`}
                    </div> */}
                  </>
                ) : (
                  <>
                    {/* <div className="time_poster" style={{ color: '#fff' }}>
                      <img
                        src={clockImg}
                        className="clock_img"
                        style={{
                          marginRight: '5px',
                        }}
                      />
                      {`${Math.floor(props?.mrrdr_runtime / 60)} hrs ${props?.mrrdr_runtime % 60 != 0
                        ? (props?.mrrdr_runtime % 60) + ' mins'
                        : ''
                        }`}
                    </div> */}
                  </>
                )}
              </div>
              {/* {filtered_movie_lang?.length > 0 && (
                <div className="time_poster text-center">{filtered_movie_lang}</div>
              )} */}
            </div>
          </div>
          {/* {!isUpcoming && !now_demand_count > 0 && (
          <div className="parent_up_btn">
            <button className="up_btn" onClick={onClick}>
              {t('Book Now')}
            </button>
          </div>
        )} */}
        </motion.article>
      </div>
    </>
  );
};

// Default Props
MovieCardNew.defaultProps = {
  img: poster,
  slider: false,
  classes: '',
  onClick: () => {},
};

export default withNamespaces()(MovieCardNew);
