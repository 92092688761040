export const API_VERSION = "v1.0";
const HOST_URL = `mif.brij.tech`;
const LIVEBASEURL = `https://mif-api.brij.tech`;
const DEVBASEURL = `http://localhost:8000`;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;

export const BASEURL =window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;
export const IMG_BASEURL = BASEURL;
export const ARENA_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYwN2VkY2MwLTNjODQtNGRhZC1hY2VmLTBjN2FhYjNmMzU1MyIsImlhdCI6MTYwNjg5MzA0MCwiZXhwIjoxNjA5NDg1MDQwfQ.3dkWIqaDYXjJ0Eayq_nL8Q0rbmqfYcyI3NLZbcbKh8Y`;
export const MIF_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImMzMzA4YTQ1LTg3MDktNGNjMy05M2Y1LTI3M2Y2Y2Q1YTRiZCIsImlhdCI6MTcxNTk0OTA2NX0.jYeCH04JQqBs-MzM5-Bqs2-1QqCgsNT9kVMAcLecK0E`;
export const TOKEN = MIF_TOKEN;








