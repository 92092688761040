import React, { useEffect, useState, useContext, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../../customHooks/useIsMobile';
import useIsTablet from '../../customHooks/useIsTablet';
import useIsDesktop from '../../customHooks/useIsDesktop';

// Images
import { ReactComponent as Facebook } from '@assets/pictures/svgs/facebook-active.svg';
import { ReactComponent as Instagram } from '@assets/pictures/svgs/instagram-active.svg';
import { ReactComponent as Twitter } from '@assets/pictures/svgs/twitter-active.svg';
import logoSample from '@assets/pictures/logo-colored.png';
import { ReactComponent as Send } from '@assets/pictures/svgs/send.svg';
import { ReactComponent as HomeIcon } from '@assets/pictures/svgs/home_new.svg';
import { ReactComponent as TicketIcon } from '@assets/pictures/svgs/ticket_new.svg';
import { ReactComponent as ProfileIcon } from '@assets/pictures/svgs/profile__new.svg';
import { Container, Row, div, Dropdown, Modal } from 'react-bootstrap';
import MobileBottomMenu from '@components/partials/MobileBottomMenu.jsx';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { CityService, LanguageService } from '@apiService/tokenService';
import { globalConfigContext } from '@context/GlobalConfigContext';
import i18n from '@plugins/i18n';
import { motion } from 'framer-motion';
import GenericService from '@apiService/GenericService';
import cinepolLogo from '@assets/pictures/cinepol_logo.jpg';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { titleCase } from '@helper/formatting';
import { setShowLoginPopup } from '@store/auth/authActions';
import AppStore from '@assets/pictures/footer_download_app1.jpeg';
import PlayStore from '@assets/pictures/footer_download_app2.jpeg';
// import DolByImage from '@assets/pictures/footer_dolby.jpeg';
// import PhfImage from '@assets/pictures/footer_img3.jpeg';
import PhfImage from '@assets/pictures/phf_new.jpeg';
// import DolByImage from '@assets/pictures/dolby_new.jpeg';
import DolByImage from '@assets/pictures/DolbyCinema New Arabic R4-02.png';
// import Facebook1 from '@assets/pictures/facebook.jpeg';
// import Twitter1 from '@assets/pictures/twitter.jpeg';
// import Instagram1 from '@assets/pictures/instagram.jpeg';
import facebook from '@assets/pictures/icons8-facebook.svg';
import twitter from '@assets/pictures/icons8-twitter.svg';
import instagram from '@assets/pictures/icons8-instagram.svg';
import youtube from '@assets/pictures/icons8-youtube.svg';
import tiktok from '@assets/pictures/icons8-tiktok.svg';
import email from '@assets/pictures/211660_email_icon.svg';
import facebook1 from '@assets/pictures/icons8-facebook1.svg';
import twitter1 from '@assets/pictures/icons8-twitter1.svg';
import instagram1 from '@assets/pictures/icons8-instagram1.svg';
import youtube1 from '@assets/pictures/icons8-youtube1.svg';
import tiktok1 from '@assets/pictures/icons8-tiktok1.svg';
import googleplay from '@assets/pictures/svgs/Google_Play_Store_badge_EN.png';
import AppStore1 from '@assets/pictures/svgs/Download_on_the_App_Store_Badge.svg.png';
import FooterLogo from '@assets/pictures/footer_logo1.jpeg';
import FooterLogoNew from '@assets/pictures/Star logo Black.png';
import logoepix from '@assets/pictures/svgs/epix-logo1.png';
import Miflogo from '@assets/pictures/mif_final_logo.png';
import MifMobilelogo from '@assets/pictures/mif_logo.png';
import faqs from '@assets/pictures/svgs/faqs.png';
import email1 from '@assets/pictures/svgs/email.png';
import { Image } from 'react-bootstrap';
// import {
//   FacebookIcon,
//   InstaIcon,
//   TicktockIcon,
//   TwiiterIcon,
//   YoutubeIcon,
// } from "../Icons";

function Footer({ t }) {
  const dispatch = useDispatch();

  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser, guestUser } = loginDetails;
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const newsLetterEmail = useRef();
  const subscribeMsg = useRef();
  const organization = useSelector((state) => state.movies.organizationDetails);

  const social_media = useSelector((state) => state.homepage.social_media_cms);
  const footer_cms = useSelector((state) => state.homepage.footer_cms);
  const CMSLOADER = useSelector((state) => state.homepage.cmsLoader);
  const [showBottomMenu, setShowBottomMenu] = useState(true);

  const { org_logo_url, org_mycinema_logo, org_name } = organization;
  const { pathname } = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  const handleLoginPopup = (toShow) => {
    dispatch(setShowLoginPopup(toShow));
  };

  useEffect(() => {
    // console.log("social_media", social_media);
  }, [social_media]);

  useEffect(() => {}, [pathname]);

  const currentTab = (path) => {
    let tab = '';
    if (path.startsWith('/home') || path.startsWith('/')) tab = 'homeTab';
    if (path.startsWith('/schedules')) tab = 'scheduleTab';
    if (path.startsWith('/guest-user-profile') || path.startsWith('/user-profile'))
      tab = 'userTab';

    return tab;
  };

  async function subscribe() {
    const payload = {
      text: newsLetterEmail.current.value,
    };
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        payload.text,
      )
    ) {
      const response = await GenericService.GetNewsLetter(payload);
      if (response.status === 200 && response.data.status === true) {
        subscribeMsg.current.innerText = t('Thank you for subscribing');
        subscribeMsg.current.className = 'text-success text-success-center';
      } else {
        subscribeMsg.current.innerText = t('You have already subscribed!');
        subscribeMsg.current.className = 'text-warning text-warning-center';
      }
      console.log(response);
    } else {
      subscribeMsg.current.innerText = t('Please enter a valid email');
      subscribeMsg.current.className = 'text-danger text-danger-center';
    }
  }

  const showItem = (item) => {
    if (!CMSLOADER) {
      if (footer_cms && footer_cms.length > 0) {
        if (footer_cms[0][item] == 'Y') {
          return true;
        } else if (footer_cms[0][item] == 'N') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const isItemActive = (item) => {
    if (!CMSLOADER) {
      if (social_media && social_media.length > 0) {
        if (social_media[0][item]) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const openUrl = (url) => {
    console.log('i am here', url);
    const newWindow = window.open(url, '_blank');
    if (newWindow) newWindow.opener = null;
  };

  if (
    pathname.startsWith('/schedules') ||
    pathname.startsWith('/fnb') ||
    pathname.startsWith('/create-show') ||
    pathname.startsWith('/screening-type') ||
    pathname.startsWith('/seat-layout') ||
    pathname.startsWith('/payment') ||
    pathname.includes('booking') ||
    pathname.startsWith('/final-ticket') ||
    pathname.startsWith('/unpaid-ticket') ||
    pathname.startsWith('/gift-card-details') ||
    pathname.startsWith('/gift-card-payment') ||
    pathname.startsWith('/gift-card-topup')

    // ||
    // (pathname.startsWith("/movie-details") &&
    //   pathname.split("/").length >= 5 &&
    //   pathname.split("/")[4] !== "2")
  ) {
    return null;
  }
  const CinemasValid = () => {
    if (
      window.location.href.includes('star') ||
      window.location.href.includes('localhost')
    ) {
      return true;
    }
    return false;
  };
  const scrollToGallery = () => {
    const gallerySection = document.getElementById('gallery');
    if (gallerySection) {
      gallerySection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // console.log(CinemasValid(), 'CinemasValid');
  return (
    <>
      <style>
        {`.random>h6{
            margin-bottom:10px !important;
          }`}
      </style>
      <footer className="footer_new" style={{ backgroundColor: '#181423' }}>
        {isMobile ? (
          // Mobile Design
          <>
            {showBottomMenu && (
              <div className="container-fluid">
                <section className="row">
                  <article className="col-12">
                    <div className="footer_new_icon_links">
                      <HomeIcon
                        className={
                          currentTab(pathname) === 'homeTab' ? 'active' : ''
                        }
                        onClick={() => {
                          history.push('/');
                        }}
                      />
                      <ProfileIcon
                        className={
                          currentTab(pathname) === 'userTab' ? 'active' : ''
                        }
                        onClick={() => {
                          if (!loggedInUser && guestUser) {
                            history.push('/guest-user-profile');
                          } else if (loggedInUser) {
                            history.push('/user-profile');
                          } else {
                            handleLoginPopup(true);
                          }
                        }}
                      />
                    </div>
                  </article>
                </section>
              </div>
            )}
          </>
        ) : isDesktop ? (
          // Desktop
          <div className="container-fluid px-0 py-5 text-white">
            <div className="row px-5">
              <div className="col-xl-5">
                <div>
                  <a href="/">
                    <Image src={Miflogo} width={200} height={200} alt="logo" />
                  </a>
                </div>
                <div className="mt-4">
                  <p style={{ fontSize: '15px', fontWeight: 'normal' }}>COMPANY</p>
                  <p
                    className=""
                    style={{
                      fontSize: '12px',
                      fontWeight: 'normal',
                      color: '#aca8b7',
                    }}
                  >
                    Misr International Films (MIF) is fully owned subsidiary of
                    Dadabai Holding operating at Dana Mall, Bahrain. It boasts 10
                    screens, with a full capacity of over 1100 seats.
                  </p>
                </div>
              </div>
              <div className="col-xl-7 mt-5 mt-xl-0">
                <div className="row">
                  <div className="col-md-2">
                    <p style={{ fontSize: '14px', fontWeight: 'normal' }}>
                      Misr International Films (MIF)
                    </p>
                    <div className="border-left border-danger newhover mt-3">
                      <a
                        className="ml-2 newhover"
                        href="now-showing"
                        style={{
                          fontSize: '11px',
                          fontWeight: 'normal',
                          color: '#aca8b7',
                        }}
                      >
                        Movies
                      </a>
                    </div>
                    <div className="border-left border-danger mt-3">
                      {/* <a className="ml-2 newhover" href="#gallarydesk" style={{fontSize:"11px", fontWeight:"normal", color:"#aca8b7"}}>
                      Theaters
                    </a> */}
                      <Link
                        className="ml-2 newhover"
                        to="/#gallery"
                        style={{
                          fontSize: '11px',
                          fontWeight: 'normal',
                          color: '#aca8b7',
                        }}
                        onClick={scrollToGallery}
                      >
                        Theaters
                      </Link>
                    </div>
                    <div className="border-left border-danger mt-3">
                      <a
                        className="ml-2 newhover"
                        href="/experience"
                        style={{
                          fontSize: '11px',
                          fontWeight: 'normal',
                          color: '#aca8b7',
                        }}
                      >
                        Experiences
                      </a>
                    </div>
                    <div className="border-left border-danger mt-3">
                      <a
                        className="ml-2 newhover"
                        href="/about-us"
                        style={{
                          fontSize: '11px',
                          fontWeight: 'normal',
                          color: '#aca8b7',
                        }}
                      >
                        About
                      </a>
                    </div>
                    <div className="border-left border-danger mt-3">
                      <a
                        className=" ml-2 newhover"
                        href="/contact-us"
                        style={{
                          fontSize: '11px',
                          fontWeight: 'normal',
                          color: '#aca8b7',
                        }}
                      >
                        Contact
                      </a>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <p style={{ fontSize: '14px', fontWeight: 'normal' }}>
                      What's ON
                    </p>
                    <div className="border-left border-danger mt-3">
                      <a
                        className="ml-2 newhover"
                        href="/now-showing"
                        style={{
                          fontSize: '11px',
                          fontWeight: 'normal',
                          color: '#aca8b7',
                        }}
                      >
                        Now Showing
                      </a>
                    </div>
                    <div className="border-left border-danger mt-3">
                      <a
                        className="ml-2 newhover"
                        href="/upcoming"
                        style={{
                          fontSize: '11px',
                          fontWeight: 'normal',
                          color: '#aca8b7',
                        }}
                      >
                        Coming Soon
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <p style={{ fontSize: '14px', fontWeight: 'normal' }}>
                      STAY IN TOUCH
                    </p>
                    <div
                      className="d-flex p-2 mt-3"
                      style={{
                        borderRadius: '10px',
                        backgroundColor: '#2b2636ce',
                        width: '170px',
                      }}
                    >
                      <img src={email1} alt="email" className="mr-1" />
                      {/* <p className="" style={{fontSize:"11px", fontWeight:"normal", color:"#aca8b7"}}>info@epixcinemas.com</p> */}
                      <a href="">
                        <p
                          className=""
                          style={{
                            fontSize: '8px',
                            fontWeight: 'normal',
                            color: '#aca8b7',
                          }}
                        >
                          www.misrinternationalfilms.com
                        </p>
                      </a>
                    </div>
                    <a href="/faqs">
                      <div
                        className="d-flex p-2 mt-3"
                        style={{
                          borderRadius: '10px',
                          backgroundColor: '#2b2636ce',
                          width: '100px',
                        }}
                      >
                        <img src={faqs} alt="faq" height={16} className="mr-1" />
                        <p
                          className=""
                          style={{
                            fontSize: '10px',
                            fontWeight: 'normal',
                            color: '#aca8b7',
                          }}
                        >
                          FAQ
                        </p>
                      </div>
                    </a>
                    <div className="d-flex p-2">
                      <div className="instagram-container">
                        <a
                          href=""
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={instagram1}
                            alt="instagram"
                            className="instagram-image"
                          />
                          {isHovered && (
                            <img
                              src={instagram}
                              alt="instagram"
                              className="hover-image"
                            />
                          )}
                        </a>
                      </div>
                      <div className="instagram-container">
                        <a
                          href=""
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={facebook}
                            alt="facebook"
                            className="instagram-image"
                          />
                          {isHovered && (
                            <img
                              src={facebook1}
                              alt="facebook"
                              className="hover-image"
                            />
                          )}
                        </a>
                      </div>
                      <div className="instagram-container">
                        <a
                          href=""
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={twitter}
                            alt="twitter"
                            className="instagram-image"
                          />
                          {isHovered && (
                            <img
                              src={twitter1}
                              alt="twitter"
                              className="hover-image"
                            />
                          )}
                        </a>
                      </div>
                      <div className="instagram-container">
                        <a
                          href=""
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={youtube}
                            alt="youtube"
                            className="instagram-image"
                          />
                          {isHovered && (
                            <img
                              src={youtube1}
                              alt="youtube"
                              className="hover-image"
                            />
                          )}
                        </a>
                      </div>
                      <div className="instagram-container">
                        <a
                          href=""
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={tiktok}
                            alt="tiktok"
                            className="instagram-image"
                          />
                          {isHovered && (
                            <img
                              src={tiktok1}
                              alt="tiktok"
                              className="hover-image"
                            />
                          )}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 text-center text-md-right ">
                    <p style={{ fontSize: '14px', fontWeight: 'normal' }}>
                      Find Us On App Store <br /> And Google Play
                    </p>
                    <img
                      src={googleplay}
                      alt="Play Store"
                      className="app_img_play cursor-pointer"
                    ></img>
                    <img
                      src={AppStore1}
                      alt="App Store"
                      className="app_img_apple cursor-pointer"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid px-0 py-5 text-white">
            <div className="row px-5">
              <div className="col-xl-5">
                <div>
                  <a href="/">
                    <Image src={Miflogo} width={212} height={90} alt="logo" />
                  </a>
                </div>
                <div className="mt-4">
                  <p style={{ fontSize: '15px', fontWeight: 'normal' }}>COMPANY</p>
                  <p
                    className=""
                    style={{
                      fontSize: '12px',
                      fontWeight: 'normal',
                      color: '#aca8b7',
                    }}
                  >
                    Misr International Films (MIF) is fully owned subsidiary of
                    Dadabai Holding operating at Dana Mall, Bahrain. It boasts 10
                    screens, with a full capacity of over 1100 seats.
                  </p>
                </div>
              </div>
              <div className="col-xl-7 mt-5 mt-xl-0">
                <div className="row">
                  <div className="col-md-2">
                    <p style={{ fontSize: '14px', fontWeight: 'normal' }}>
                      Misr International Films (MIF)
                    </p>
                    <div className="border-left border-danger newhover mt-3">
                      <a
                        className="ml-2 newhover"
                        href="now-showing"
                        style={{
                          fontSize: '11px',
                          fontWeight: 'normal',
                          color: '#aca8b7',
                        }}
                      >
                        Movies
                      </a>
                    </div>
                    <div className="border-left border-danger mt-3">
                      {/* <a className="ml-2 newhover" href="#" style={{fontSize:"11px", fontWeight:"normal", color:"#aca8b7"}}>
                      Theaters
                    </a> */}
                      <Link
                        className="ml-2 newhover"
                        to="/#gallery"
                        style={{
                          fontSize: '11px',
                          fontWeight: 'normal',
                          color: '#aca8b7',
                        }}
                        onClick={scrollToGallery}
                      >
                        Theaters
                      </Link>
                    </div>
                    <div className="border-left border-danger mt-3">
                      <a
                        className="ml-2 newhover"
                        href="/experience"
                        style={{
                          fontSize: '11px',
                          fontWeight: 'normal',
                          color: '#aca8b7',
                        }}
                      >
                        Experiences
                      </a>
                    </div>
                    <div className="border-left border-danger mt-3">
                      <a
                        className="ml-2 newhover"
                        href="/about-us"
                        style={{
                          fontSize: '11px',
                          fontWeight: 'normal',
                          color: '#aca8b7',
                        }}
                      >
                        About
                      </a>
                    </div>
                    <div className="border-left border-danger mt-3">
                      <a
                        className=" ml-2 newhover"
                        href="/contact-us"
                        style={{
                          fontSize: '11px',
                          fontWeight: 'normal',
                          color: '#aca8b7',
                        }}
                      >
                        Contact
                      </a>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <p style={{ fontSize: '14px', fontWeight: 'normal' }}>
                      What's ON
                    </p>
                    <div className="border-left border-danger mt-3">
                      <a
                        className="ml-2 newhover"
                        href="/now-showing"
                        style={{
                          fontSize: '11px',
                          fontWeight: 'normal',
                          color: '#aca8b7',
                        }}
                      >
                        Now Showing
                      </a>
                    </div>
                    <div className="border-left border-danger mt-3">
                      <a
                        className="ml-2 newhover"
                        href="/upcoming"
                        style={{
                          fontSize: '11px',
                          fontWeight: 'normal',
                          color: '#aca8b7',
                        }}
                      >
                        Coming Soon
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <p style={{ fontSize: '14px', fontWeight: 'normal' }}>
                      STAY IN TOUCH
                    </p>
                    <div
                      className="d-flex p-2 mt-3"
                      style={{
                        borderRadius: '10px',
                        backgroundColor: '#2b2636ce',
                        width: '170px',
                      }}
                    >
                      <img src={email1} alt="email" className="mr-1" />
                      {/* <p className="" style={{fontSize:"11px", fontWeight:"normal", color:"#aca8b7"}}>info@epixcinemas.com</p> */}
                      <a href="">
                        <p
                          className=""
                          style={{
                            fontSize: '8px',
                            fontWeight: 'normal',
                            color: '#aca8b7',
                          }}
                        >
                          www.misrinternationalfilms.com
                        </p>
                      </a>
                    </div>
                    <a href="/faqs">
                      <div
                        className="d-flex p-2 mt-3"
                        style={{
                          borderRadius: '10px',
                          backgroundColor: '#2b2636ce',
                          width: '100px',
                        }}
                      >
                        <img src={faqs} alt="faq" height={16} className="mr-1" />
                        <p className="">
                          <a
                            href="/faqs"
                            style={{
                              fontSize: '10px',
                              fontWeight: 'normal',
                              color: '#aca8b7',
                            }}
                          >
                            FAQ
                          </a>
                        </p>
                      </div>
                    </a>
                    <div className="d-flex p-2">
                      <div className="instagram-container">
                        <a
                          href=""
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={instagram1}
                            alt="instagram"
                            className="instagram-image"
                          />
                          {isHovered && (
                            <img
                              src={instagram}
                              alt="instagram"
                              className="hover-image"
                            />
                          )}
                        </a>
                      </div>
                      <div className="instagram-container">
                        <a
                          href=""
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={facebook}
                            alt="facebook"
                            className="instagram-image"
                          />
                          {isHovered && (
                            <img
                              src={facebook1}
                              alt="facebook"
                              className="hover-image"
                            />
                          )}
                        </a>
                      </div>
                      <div className="instagram-container">
                        <a
                          href=""
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={twitter}
                            alt="twitter"
                            className="instagram-image"
                          />
                          {isHovered && (
                            <img
                              src={twitter1}
                              alt="twitter"
                              className="hover-image"
                            />
                          )}
                        </a>
                      </div>
                      <div className="instagram-container">
                        <a
                          href=""
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={youtube}
                            alt="youtube"
                            className="instagram-image"
                          />
                          {isHovered && (
                            <img
                              src={youtube1}
                              alt="youtube"
                              className="hover-image"
                            />
                          )}
                        </a>
                      </div>
                      <div className="instagram-container">
                        <a
                          href=""
                          target="_blank"
                          rel="noopener noreferrer"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img
                            src={tiktok}
                            alt="tiktok"
                            className="instagram-image"
                          />
                          {isHovered && (
                            <img
                              src={tiktok1}
                              alt="tiktok"
                              className="hover-image"
                            />
                          )}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 text-center text-md-right ">
                    <p style={{ fontSize: '14px', fontWeight: 'normal' }}>
                      Find Us On App Store <br /> And Google Play
                    </p>
                    <img
                      src={googleplay}
                      alt="Play Store"
                      className="app_img_play cursor-pointer"
                    ></img>
                    <img
                      src={AppStore1}
                      alt="App Store"
                      className="app_img_apple cursor-pointer"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </footer>

      {!isMobile && !isTablet && (
        <div
          className=" mt-4 mb-4 "
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 30px',
          }}
        >
          <div className="d-flex">
            <p style={{ fontSize: '12px', fontWeight: 'normal', color: '#aca8b7' }}>
              Copyright © 2022. All Rights Reserved By :
            </p>
            <span
              style={{ fontSize: '12px', fontWeight: 'normal', color: 'white' }}
            >
              Misr International Films (MIF)
            </span>
          </div>
          <div style={{ fontSize: '12px', fontWeight: 'normal', color: '#aca8b7' }}>
            <p style={{ fontSize: '12px', fontWeight: 'normal', color: '#aca8b7' }}>
              <a
                href="/tnc"
                className="newhover"
                style={{ fontSize: '12px', fontWeight: 'normal', color: '#aca8b7' }}
              >
                Term Conditions
              </a>
              <span className="ml-2">|</span>
              <a
                href="/privacy-policy"
                className="ml-2 newhover"
                style={{ fontSize: '12px', fontWeight: 'normal', color: '#aca8b7' }}
              >
                Privacy Policy
              </a>
              <span className="ml-2">|</span>
              <a
                href="/disclaimers"
                className="ml-2 newhover"
                style={{ fontSize: '12px', fontWeight: 'normal', color: '#aca8b7' }}
              >
                Disclaimers
              </a>
            </p>
          </div>
          <div className="d-flex">
            <p style={{ fontSize: '12px', fontWeight: 'normal', color: '#aca8b7' }}>
              Developed by :
            </p>
            <a
              href="https://www.brij.tech/"
              style={{ fontSize: '12px', fontWeight: 'normal', color: 'white' }}
            >
              Brij
            </a>
          </div>
        </div>
      )}
      {isTablet && (
        <div
          className=" mt-4 mb-4 "
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 30px',
          }}
        >
          <div className="d-flex">
            <p style={{ fontSize: '10px', fontWeight: 'normal', color: '#aca8b7' }}>
              Copyright © 2022. All Rights Reserved By :
            </p>
            <span
              style={{ fontSize: '10px', fontWeight: 'normal', color: 'white' }}
            >
              Misr International Films (MIF)
            </span>
          </div>
          <div style={{ fontSize: '10px', fontWeight: 'normal', color: '#aca8b7' }}>
            <p style={{ fontSize: '10px', fontWeight: 'normal', color: '#aca8b7' }}>
              <a
                href="/tnc"
                style={{ fontSize: '10px', fontWeight: 'normal', color: '#aca8b7' }}
              >
                Term Conditions
              </a>
              <span className="ml-2">|</span>
              <a
                href="/privacy-policy"
                className="ml-2"
                style={{ fontSize: '10px', fontWeight: 'normal', color: '#aca8b7' }}
              >
                Privacy Policy
              </a>
              <span className="ml-2">|</span>
              <a
                href="/disclaimers"
                className="ml-2"
                style={{ fontSize: '10px', fontWeight: 'normal', color: '#aca8b7' }}
              >
                Disclaimers
              </a>
            </p>
          </div>
          <div className="d-flex">
            <p style={{ fontSize: '10px', fontWeight: 'normal', color: '#aca8b7' }}>
              Developed by :
            </p>
            <a
              href="https://www.brij.tech/"
              style={{ fontSize: '10px', fontWeight: 'normal', color: 'white' }}
            >
              Brij
            </a>
          </div>
        </div>
      )}
    </>
  );
}
export default withNamespaces()(Footer);
