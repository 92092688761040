import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import Button from '@components/partials/Button';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import { setShowLoginPopup } from '../../../store/auth/authActions';
import { useDispatch } from 'react-redux';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import aboutUsImg1 from '@assets/pictures/about_us_1.jpeg';
import aboutUsImg2 from '@assets/pictures/about_us_2.jpeg';
import aboutUsImg3 from '@assets/pictures/about_us_3.jpeg';
import aboutUsImg4 from '@assets/pictures/about_us_4.jpeg';
import aboutUsImg5 from '@assets/pictures/about_us_5.jpeg';
import aboutUsImg6 from '@assets/pictures/about_us_6.jpeg';
import aboutUsMap from '@assets/pictures/about_us_new_map.jpeg';
import abouthomeimage from '@assets/pictures/abouthomeimage.png';

const AboutUsHeroText = (props) => {
  const { t, classes = '' } = props;
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [register, setRegister] = useState(false);

  const dispatch = useDispatch();
  const handleLoginPopup = (toShow) => {
    setRegister(toShow);
    dispatch(setShowLoginPopup(toShow));
  };

  return (
    <>
      <section className={`container-fluid about_us_hero_text ${classes}`}>
        <div className="row">
          <article className="col-lg-10 col-xl-12 mx-auto">
            {/* <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('About Us')} /> */}
            {/* <CenteredTitle firstHalf={'Welcome To Epix Cinema'} secondHalf={''} /> */}
            <h1>
              <span style={{ fontWeight: '600' }}>Welcome To</span>
              <span className="aboutheading" style={{ color: '#ffb230' }}>
                {' '}
                Misr International Films (MIF)
              </span>
            </h1>
            <div className="row">
              <section
                className="col-xl-12 col-md-12"
                style={{ marginTop: '30px' }}
              >
                <span
                  className={`f-14 ${isMobile ? 'ml-0' : ''}`}
                  style={{ margin: '5px' }}
                >
                  Thank you for your interest in Misr International Films (MIF)!
                </span>
                <section className="mt-4" style={{ color: '#aca8b7' }}>
                  <p
                    className={`about_us_hero_text_synopsis aboutpara ${
                      isMobile ? 'mb-3 mt-3' : ''
                    }`}
                    style={{ margin: '12px 5px' }}
                  >
                    Misr International Films (MIF) was founded in 1972 by the late,
                    world-renowned Egyptian filmmaker Youssef Chahine, whose films
                    have been key in depicting modern Egypt and capturing the
                    complexity of its society and have received international
                    acclaim, winning numerous international prizes culminating in
                    the lifetime achievement award in 1997 at the Cannes
                    International Film Festival. Today, MIF is an integrated film
                    and TV company with substantial experience in every aspect of
                    the production, distribution and exhibition processes.
                  </p>
                  <p
                    className={`about_us_hero_text_synopsis aboutpara ${
                      isMobile ? 'mb-3 mt-3' : ''
                    }`}
                    style={{ margin: '12px 5px' }}
                  >
                    MIF has also been very active on the international scene,
                    pioneering several major co-productions, and as executive
                    producer, providing comprehensive services to foreign companies
                    filming in Egypt.
                  </p>
                  <span className="aboutheading" style={{ color: '#ffb230' }}>
                    {' '}
                    OUR MISSION
                  </span>
                  <p
                    className={`about_us_hero_text_synopsis aboutpara ${
                      isMobile ? 'mb-3 mt-3' : ''
                    }`}
                    style={{ margin: '12px 5px' }}
                  >
                    To deliver diverse stories to audiences through the Arab region
                    and the world and to provide excellence across all facets of the
                    Motion Picture industry including Production, Distribution and
                    Exhibition.
                  </p>
                  <span className="aboutheading" style={{ color: '#ffb230' }}>
                    {' '}
                    OUR VISION
                  </span>
                  <p
                    className={`about_us_hero_text_synopsis aboutpara ${
                      isMobile ? 'mb-3 mt-3' : ''
                    }`}
                    style={{ margin: '12px 5px' }}
                  >
                    To be the partner of choice in the film industry in the MENA
                    region.
                  </p>
                </section>
              </section>
            </div>
          </article>
        </div>
      </section>
    </>
  );
};

export default withNamespaces()(AboutUsHeroText);
